<i18n lang="json">{
    "ru": {
        "Сейф": "Сейф",
        "от 500 руб. в день": "от 500 руб. в день",
        "Карта": "Карта",
        "за 2 клика": "за 2 клика",
        "Вклад": "Вклад",
        "Пополняемый": "Пополняемый",
        "ONLINE 24": "ONLINE 24",
        "Ваш банк в смартфоне": "Ваш банк в смартфоне",
        "Аренда сейфовой ячейки": "Безопасно. Удобно. Выгодно. Конфиденциально",
        "Арендовать": "Арендовать",
        "Для личного хранения ценностей и проведения сделок": "Для личного хранения ценностей и проведения сделок",
        "Дебетовая карта": "Дебетовая карта",
        "Получить": "Получить",
        "За два клика и с уникальным дизайном": "За два клика и с уникальным дизайном",
        "Вклад “Пополняемый”": "Вклад “Пополняемый”",
        "Подробнее": "Подробнее",
        "Пополняйте в любое время и получайте до 8% по вкладу": "Пополняйте в любое время и получайте до 8% по вкладу",
        "Банк в кармане": "Банк в кармане",
        "Скачать": "Скачать",
        "приложение ONLINE 24 для бысрого доступа к счёту": "Мобильное приложение АМРА для быстрого доступа к вашему счету"
    },
    "en": {
        "Сейф": "Safe",
        "от 500 руб. в день": "from 500 rub. per day",
        "Карта": "Card",
        "за 2 клика": "in 2 clicks",
        "Вклад": "Deposit",
        "Пополняемый": "Replenishable",
        "ONLINE 24": "ONLINE 24",
        "Ваш банк в смартфоне": "Your bank on your smartphone",
        "Аренда сейфовой ячейки": "Safely. Comfortable. Profitable. Confidentially",
        "Арендовать": "Rent",
        "Для личного хранения ценностей и проведения сделок": "For personal storage of valuables and transactions",
        "Дебетовая карта": "Debit Card",
        "Получить": "Get",
        "За два клика и с уникальным дизайном": "In two clicks with a unique design",
        "Вклад “Пополняемый”": "Deposit “Replenishable”",
        "Подробнее": "Learn More",
        "Пополняйте в любое время и получайте до 8% по вкладу": "Replenish at any time and get up to 8% on your deposit",
        "Банк в кармане": "Bank in your pocket",
        "Скачать": "Download",
        "приложение ONLINE 24 для бысрого доступа к счёту": "ONLINE 24 app for quick access to your account"
    },
    "es": {
        "Сейф": "Caja fuerte",
        "от 500 руб. в день": "desde 500 rub. por día",
        "Карта": "Tarjeta",
        "за 2 клика": "en 2 clics",
        "Вклад": "Depósito",
        "Пополняемый": "Reponible",
        "ONLINE 24": "ONLINE 24",
        "Ваш банк в смартфоне": "Su banco en su smartphone",
        "Аренда сейфовой ячейки": "Sin peligro. Cómodo. Rentable. Confidencialmente",
        "Арендовать": "Alquilar",
        "Для личного хранения ценностей и проведения сделок": "Para almacenamiento personal de objetos de valor y transacciones.",
        "Дебетовая карта": "Tarjeta de débito",
        "Получить": "Obtener",
        "За два клика и с уникальным дизайном": "En dos clics con un diseño único",
        "Вклад “Пополняемый”": "Depósito “Reponible”",
        "Подробнее": "Más información",
        "Пополняйте в любое время и получайте до 8% по вкладу": "Reponga en cualquier momento y obtenga hasta un 8% en su depósito",
        "Банк в кармане": "Banco en su bolsillo",
        "Скачать": "Descargar",
        "приложение ONLINE 24 для бысрого доступа к счёту": "app ONLINE 24 para acceso rápido a su cuenta"
    },
    "ar": {
        "Сейф": "خزنة",
        "от 500 руб. в день": "من 500 روبل في اليوم",
        "Карта": "بطاقة",
        "за 2 клика": "في نقرتين",
        "Вклад": "إيداع",
        "Пополняемый": "قابل للتجديد",
        "ONLINE 24": "ONLINE 24",
        "Ваш банк в смартфоне": "بنكك على هاتفك الذكي",
        "Аренда сейфовой ячейки": "بأمان. مريح. مربحة. بسرية",
        "Арендовать": "استئجار",
        "Для личного хранения ценностей и проведения сделок": "للتخزين الشخصي للأشياء الثمينة والمعاملات",
        "Дебетовая карта": "بطاقة الخصم",
        "Получить": "الحصول على",
        "За два клика и с уникальным дизайном": "في نقرتين وبتصميم فريد",
        "Вклад “Пополняемый”": "إيداع “قابل للتجديد”",
        "Подробнее": "المزيد من التفاصيل",
        "Пополняйте в любое время и получайте до 8% по вкладу": "جدد في أي وقت واحصل على ما يصل إلى 8٪ على وديعتك",
        "Банк в кармане": "بنك في جيبك",
        "Скачать": "تحميل",
        "приложение ONLINE 24 для бысрого доступа к счёту": "تطبيق ONLINE 24 للوصول السريع إلى حسابك"
    },
    "tr": {
        "Сейф": "Kasa",
        "от 500 руб. в день": "günde 500 rub. dan",
        "Карта": "Kart",
        "за 2 клика": "2 tıklama ile",
        "Вклад": "Mevduat",
        "Пополняемый": "Yenilenebilir",
        "ONLINE 24": "ONLINE 24",
        "Ваш банк в смартфоне": "Akıllı telefonunuzda bankanız",
        "Аренда сейфовой ячейки": "Güvenli bir şekilde. Rahat. Kârlı. Gizlice",
        "Арендовать": "Kiralamak",
        "Для личного хранения ценностей и проведения сделок": "Değerli eşyaların ve işlemlerin kişisel olarak saklanması için",
        "Дебетовая карта": "Banka Kartı",
        "Получить": "Almak",
        "За два клика и с уникальным дизайном": "İki tıklamayla ve benzersiz bir tasarımla",
        "Вклад “Пополняемый”": "Mevduat “Yenilenebilir”",
        "Подробнее": "Daha fazla bilgi",
        "Пополняйте в любое время и получайте до 8% по вкладу": "Her zaman yenileyin ve mevduatınıza %8'e kadar alın",
        "Банк в кармане": "Cebinizdeki banka",
        "Скачать": "İndir",
        "приложение ONLINE 24 для бысрого доступа к счёту": "Hesabınıza hızlı erişim için ONLINE 24 uygulaması"
    }
}</i18n>

<template>
    <section class="bg-gray lg:mb-24 lg:pb-0 pb-[6px]">
        <div class="container relative px-4 md:px-0">
            <div class="lg:block hidden">
                <div v-if="mainCategories[activeIndex]" class="flex items-center justify-between h-117">
                    <div class="grid gap-7">
                        <p class="text-4xl font-semibold">{{ t(mainCategories[activeIndex].title) }}</p>
                        <p class="text-xl">{{ t(mainCategories[activeIndex].description) }}</p>
                        <nuxt-link :to="localePath(buttonPaths[activeIndex])">
                            <AButton type="yellow" font-size="lg" width="medium">
                                {{ t(mainCategories[activeIndex].button) }}
                            </AButton>
                        </nuxt-link>
                    </div>
                    <component :is="mainCategories[activeIndex].iconComponent"></component>
                </div>
            </div>
            <div class="block lg:hidden overflow-hidden">
                <div v-if="mainCategories">
                    <swiper slides-per-view="1" @slideChange="onSlideChange"
                        :autoplay="{ delay: 8000, disableOnInteraction: false }">
                        <swiper-slide v-for="(category, index) in mainCategories" :key="index"
                            class="flex justify-center relative !h-93">
                            <div class=" grid pt-5 h-[300px]">
                                <div>
                                    <p class="text-3xl font-semibold">{{ t(category.title) }} -</p>
                                    <p>{{ t(category.description) }}</p>
                                </div>
                                <div class="absolute bottom-0 w-full">
                                    <div class="flex justify-center">
                                        <component :is="category.iconComponentMobile"></component>
                                    </div>
                                    <nuxt-link :to="localePath(buttonPaths[activeIndex])" class="block w-full">
                                        <AButton width="mobile" class="!absolute !w-full bottom-0">
                                            {{ t(mainCategories[activeIndex].button) }}
                                        </AButton>
                                    </nuxt-link>
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
            <div class="lg:block hidden">
                <div class="-bottom-14 flex gap-[1px] items-end absolute justify-center w-full">
                    <div v-for="(item, index) in subCategories" :key="index" class="cursor-pointer">
                        <nuxt-link :class="[
                            'flex flex-row gap-3 pl-5 items-center w-[254px] h-30 bg-darkGrey opacity-90',
                            {
                                'border-darkGrey rounded-t-3xl border-[1px] h-35 bg-white ': item.isActive,
                                'rounded-l-xl': !item.isActive && index === 0,
                                'rounded-r-xl': !item.isActive && index === subCategories.length - 1
                            }
                        ]" @click="switchActiveComponent(index)">
                            <div class="text-[#5A5A5A] text-sm">
                                <p class="font-medium text-lg">{{ t(item.title) }}</p>
                                <p>{{ t(item.description) }}</p>
                            </div>
                            <div>
                                <component :is="item.iconComponent"></component>
                            </div>
                        </nuxt-link>
                        <div class="h-[6px] bg-[#FCC900] transition-width duration-5000 rounded-r-xl"
                            :style="{ display: item.isActive ? 'block' : 'none', width: item.isActive ? sliderWidth + '%' : '0%' }">
                        </div>
                    </div>
                </div>
            </div>
            <div class="block lg:hidden">
                <div class="flex mt-3 gap-[10px] justify-center ">
                    <div v-for="(item, index) in subCategories" :key="index" class="cursor-pointer">
                        <nuxt-link @click.prevent="switchActiveComponent(index)">
                            <div class="">
                                <div :class="[
                                    'h-[10px] transition-width duration-5000 rounded-full',
                                    { 'w-[10px] bg-[#FCC900]': item.isActive, 'bg-[#D9D9D9] w-[10px]': !item.isActive }
                                ]"></div>
                            </div>
                        </nuxt-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import { useI18n } from 'vue-i18n';
import safe_mobile from './a-icon/a-icon-safe-mobile.vue';
import cart_mobile from './a-icon/a-icon-cart-mobile.vue';
import contributioni_mobile from './a-icon/a-icon-contribution-mobile.vue';
import qr_mobile from './a-icon/a-icon-qr-mobile.vue';
import safeicon from '@/components/a-icon/a-icon-safe.vue';
import safe from '@/components/a-icon/a-icon-slide-safe.vue';
import cart from '@/components/a-icon/a-icon-slide-cart.vue';
import contribution from '@/components/a-icon/a-icon-slide-contribution.vue';
import qr from '@/components/a-icon/a-icon-slide-qr.vue';
import carticon from '@/components/a-icon/a-icon-cart.vue';
import contributionicon from '@/components/a-icon/a-icon-contribution.vue';
import smartphone from '@/components/a-icon/a-icon-smartphone.vue';

const { t } = useI18n({ useScope: 'local' });
const sliderWidth = ref(0);
const activeIndex = ref(0);

const subCategories = ref([
    {
        title: 'Карта',
        description: 'за 2 клика',
        iconComponent: carticon,
        isActive: false

    },
    {
        title: 'ONLINE 24',
        description: 'Ваш банк в смартфоне',
        iconComponent: smartphone,
        isActive: false
    },
    {
        title: 'Вклад',
        description: '«Пополняемый»',
        iconComponent: contributionicon,
        isActive: false
    },
    {
        title: 'Сейф',
        description: 'от 500 руб. в день',
        iconComponent: safeicon,
        isActive: true
    }
]);

const mainCategories = ref([
    {
        title: 'Дебетовая карта',
        button: 'Получить',
        description: 'За два клика и с уникальным дизайном',
        iconComponentMobile: cart_mobile,
        iconComponent: cart,
        isActive: false

    },
    {
        title: 'Банк в кармане',
        button: 'Скачать',
        description: 'приложение ONLINE 24 для бысрого доступа к счёту',
        iconComponentMobile: qr_mobile,
        iconComponent: qr,
        isActive: false
    },
    {
        title: 'Вклад “Пополняемый”',
        button: 'Подробнее',
        description: 'Пополняйте в любое время и получайте до 8% по вкладу',
        iconComponentMobile: contributioni_mobile,
        iconComponent: contribution,
        isActive: false
    },
    {
        title: 'Аренда сейфовой ячейки',
        button: 'Арендовать',
        description: 'Для личного хранения ценностей и проведения сделок',
        iconComponentMobile: safe_mobile,
        iconComponent: safe,
        isActive: true
    }
]);

const buttonPaths = [
    '/cards',
    '/online24',
    '/deposits',
    '/safeboxes',
];

const switchActiveComponent = (index) => {
    subCategories.value.forEach((item, i) => {
        item.isActive = i === index;
    });
    activeIndex.value = index;
    sliderWidth.value = 0;
    setTimeout(() => {
        sliderWidth.value = 100;
    }, 100);
};

const onSlideChange = (swiper) => {
    switchActiveComponent(swiper.activeIndex);
};

onMounted(() => {
    setInterval(() => {
        const nextIndex = (activeIndex.value + 1) % subCategories.value.length;
        switchActiveComponent(nextIndex);
    }, 8000);
});

watch(activeIndex, (newIndex) => {
    const swiper = document.querySelector('.swiper').swiper;
    swiper.slideTo(newIndex);
});
</script>

<style scoped>
.transition-width {
    transition: width 8s;
}
</style>